import React from "react";
import "./index.css"; // Tailwind CSS styles

import Home from "./pages/home";

function App() {
  return <Home />;
}

export default App;
