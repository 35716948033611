import React from "react";
import FeatureItem from "../../components/feature-item";

const FeaturesList = () => {
  return (
    <div className="my-[15%] md:my-[10%]">
      <h3 className="text-3xl font-bold text-center">Why Choose Us</h3>
      <p className="text-lg font-light text-center mt-4 max-w-2xl mx-auto mb-12 px-8 md:px-14">We combine technology with verified profiles, preferences with flexibility, efficiency with ease, and support with excellence—ensuring a seamless hiring experience.</p>
      <div className="w-full h-auto flex flex-col md:flex-row mt-16 md:mt-0 py-0 md:py-16 items-center justify-between px-8 md:px-14">
        <FeatureItem
          icon={require("../../assets/save-time.png")}
          title={"Save Time & Effort"}
          description={
            "Quickly post your requirements and receive responses without the hassle of searching."
          }
          iconClassName="w-8 h-8"
        />

        <FeatureItem
          icon={require("../../assets/contact.png")}
          title={"Simplified Hiring Process"}
          description={
            "Easily connect with interested candidates and hire the right fit in no time."
          }
          iconClassName="w-12 h-12"

        />
        
        <FeatureItem
          icon={require("../../assets/secure.png")}
          title={"Verified Candidates"}
          description={
            "Access a trusted network of skilled cooks and maids with verified profiles."
          }
          iconClassName="w-8 h-8"

        />

      </div>
    </div>
  );
};

export default FeaturesList;
