import React from 'react'

const Footer = () => {
  return (
    <div className='w-full relative bottom-0 px-8 md:px-14 py-8 md:py-12 bg-primary mt-12 md:mt-0'>
        <div className='flex flex-col md:flex-row justify-between items-start'>
            <div>
                <p className='font-thin text-white text-sm'>Phone: +91 7995740545</p>
                <p className='font-thin text-white text-sm'>Email: support@cooksandmaids.in</p>
                <p className='font-thin text-white text-sm'>Website: www.cooksandmaids.in</p>
                <p className='font-thin text-white text-sm mt-6 hidden md:block'>Copyright © 2025 Cooks and Maids</p>
            </div>
            <div className='flex flex-col justify-center items-start md:items-center mt-8 md:mt-0 '>
                <p className='font-thin text-white text-sm mb-4'>Scan to download</p>
                <img src={require("../../assets/qr-code.png")} alt="Google Play" className='w-20 h-20' />
            </div>
        </div>
        <p className='font-thin text-white text-sm mt-6 block md:hidden'>Copyright © 2025 Cooks and Maids</p>
    </div>
  )
}

export default Footer
