import React from 'react'


const FeatureItem = ({ icon, title, description, iconClassName = "" }) => {
  return (
    <div className='max-w-[300px] py-4 md:py-0 px-0 md:px-4'>
      <img src={icon} alt={title} className={`${iconClassName ? iconClassName : 'w-12 h-12'}`}/>
      <h5 className='text-xl font-bold my-2'>{title}</h5>
      <span className='text-base'>{description}</span>
    </div>
  )
}

export default FeatureItem
