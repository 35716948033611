import React from "react";
import Logo from "../../components/logo";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import FeaturesList from "./features-list";
import Footer from "./footer";

const Home = () => {
  const handleClickGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.cooksandmaids",
      "_blank"
    );
  };

  return (
    <div className="min-h-screen flex flex-col relative max-w-[1512px] mx-auto">
      <div className="absolute w-full h-full overflow-hidden">
        <div className="absolute w-full h-[120vh] md:w-[180vw] md:h-[140vh] lg:h-[220vh] xl:w-[160vw] xl:h-[160vh] 2xl:w-[80vw] 2xl:h-[90vh] transform bg-primary rounded-none md:rounded-full z-0 rotate-0 md:-rotate-12 translate-x-0 translate-y-0 md:translate-x-[-25%] md:translate-y-[-53%]" />
      </div>

      <nav className="flex justify-between items-center pt-4 md:pt-6 text-white px-6 md:px-14 relative z-10">
        <Logo />
      </nav>

      <div className="flex flex-col md:flex-row justify-center md:justify-between items-start px-8 md:px-14 relative z-10">
        <div className="md:w-1/2 md:text-left py-12">
          <h5 className="text-2xl font-thin text-white mb-2">
            Find perfect Cook or Maid
          </h5>
          <h3 className="text-4xl font-bold text-white mb-12">
            Effortlessly !
          </h3>

          <p className="text-lg text-white font-light mb-4 md:w-3/4">
            Tired of searching through endless profiles? Post your requirement
            and get responses from 1000+ verified cooks and maids effortlessly!
          </p>

          <p className="text-lg text-white font-light mb-16 md:w-3/4">
            Tired of searching through endless profiles? Post your requirement
            and get responses from 1000+ verified cooks and maids effortlessly!
          </p>

          <div className="flex justify-center md:justify-start">
            <img
              src={require("../../assets/google-play.png")}
              alt="Google Play"
              className="rounded-lg cursor-pointer"
              style={{ width: "180px", height: "auto" }}
              onClick={handleClickGooglePlay}
            />
          </div>
        </div>
        <div className="mt-12 md:mt-0">
          <DeviceFrameset
            device="HTC One"
            color="black"
            width={300}
            height={550}
          >
            <img
              src={require("../../assets/home-screen.jpg")}
              alt="Screen"
              className="cursor-pointer"
              style={{ width: "100%", height: "auto" }}
              onClick={handleClickGooglePlay}
            />
          </DeviceFrameset>
        </div>
      </div>

      <FeaturesList />

      <Footer />
    </div>
  );
};

export default Home;
