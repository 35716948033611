import React from "react";

const Logo = () => {
  return (
    <div className="flex items-center cursor-pointer">
      <img
        src={require("../../assets/logo.png")}
        alt="Google Play"
        style={{ width: "50px", height: "50px" }}
      />
      <h5 className="text-2xl font-thin text-white">Cooks and Maids</h5>
    </div>
  );
};

export default Logo;
